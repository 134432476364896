import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import {
  User,
  UserRolePermission,
  UserRolePermissionScope,
  UserRolePermissionTarget,
} from '../../../generated/graphql';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  me: any;
  loading = true;
  env= environment
  scope = UserRolePermissionTarget;
  constructor(
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
    this.authService.me().subscribe(({data, loading}) => {
      this.me = data.me as User;
      this.loading = loading;
    })
  }

  showNavigation(target: UserRolePermissionTarget) {
    return this.me?.role?.permissions?.some((p: UserRolePermission) => p.scope == UserRolePermissionScope.Read && p.target === target)
  }

}
