import { Injectable } from '@angular/core';
import {tap} from 'rxjs/operators';
import {Apollo} from "apollo-angular";
import {BehaviorSubject, Observable} from "rxjs";
import jwt_decode from "jwt-decode";
import {
  LoginGQL,
  MeGQL,
  RequestPasswordResetGQL,
  UserLoginInput,
  RequestPasswordResetInput,
  SetEmployeePasswordInput,
  SetEmployeePasswordGQL,
  NotificationsGQL,
  MarkNotificationsAsReadGQL,
  MarkNotificationsAsReadInput
} from '../../generated/graphql';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private _isAuthenticated = new BehaviorSubject(false);
  constructor(
    private apollo: Apollo,
    private meGQL: MeGQL,
    private loginGQL: LoginGQL,
    private requestPasswordResetGQL: RequestPasswordResetGQL,
    private setEmployeePasswordGQL: SetEmployeePasswordGQL,
    private notificationsGQL: NotificationsGQL,
    private markNotificationGQL : MarkNotificationsAsReadGQL,
  ) { }

  me() {
    return this.meGQL.watch().valueChanges;
  }

  get isAuthenticated(): Observable<boolean> {
    return this._isAuthenticated.asObservable();
  }

  autoLogin(): boolean {
    if(localStorage.getItem('token')) {
      // @ts-ignore
      const decode: any = jwt_decode(localStorage.getItem('token'));
      const exp = decode.exp;
      const date = new Date(0);
      date.setUTCSeconds(exp);
      if(Date.now() > date.getTime()) {
        return false;
      } else {
        this._isAuthenticated.next(true);
        return true;
      }
    } else {
      return false;
    }
  }

  login(input: UserLoginInput) {
    return this.loginGQL.mutate({
      input
    }).pipe(tap(({data, errors, loading}) => {
      if(data?.userLogin.string) {
        this._isAuthenticated.next(true);
        localStorage.setItem('token',data?.userLogin.string);
      }
      return {
        data,
        errors,
        loading
      }
    }))
  }

  logout() {
    localStorage.removeItem('token');
    this.apollo.client.resetStore();
  }

  onRequestReset(input: RequestPasswordResetInput){
    return this.requestPasswordResetGQL.mutate({
      input
    })
  }

  changePassword(input: SetEmployeePasswordInput){
    return this.setEmployeePasswordGQL.mutate({
      input
    })
  }

  getNotifications() {
    return this.notificationsGQL.watch(undefined,{
      pollInterval: 3000,
    }).valueChanges;
  }

  markNotification(input: MarkNotificationsAsReadInput){
    return this.markNotificationGQL.mutate({input} , {
      refetchQueries: [
        {
          query: this.notificationsGQL.document
        }
      ]
    })
  }
}
