<div id="app-sidebar-1" class="surface-section h-screen hidden lg:block flex-shrink-0 fixed lg:sticky left-0 top-0 z-1 border-right-1 surface-border select-none" style="width:280px">
  <div class="flex flex-column h-full">
    <div class="flex align-items-center px-5 flex-shrink-0 pt-5" style="height:60px">
      <img src="assets/images/logo.svg" alt="Image" height="60">
    </div>
    <div class="overflow-y-auto">
      <ul class="list-none p-3 m-0 mt-3">
        <li>
          <ul class="list-none p-0 m-0 overflow-hidden">
            <li routerLink="/dashboard">
              <a pRipple class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors text-lg">
                <i class="pi pi-home mr-2 text-lg"></i>
                <span class="font-medium">Dashboard</span>
              </a>
            </li>
            <li routerLink="/users">
              <a pRipple class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors text-lg">
                <i class="pi pi-users mr-2 text-lg"></i>
                <span class="font-medium">Benutzer</span>
              </a>
            </li>
            <li *ngIf='showNavigation(scope.Branch)' routerLink="/branches">
              <a pRipple class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors text-lg">
                <i class="pi pi-map-marker mr-2 text-lg"></i>
                <span class="font-medium">Niederlassungen</span>
              </a>
            </li>
<!--            <li routerLink="/departments">-->
<!--              <a pRipple class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors text-lg">-->
<!--                <i class="pi pi-building mr-2 text-lg"></i>-->
<!--                <span class="font-medium">Abteilungen</span>-->
<!--              </a>-->
<!--            </li>-->
            <li routerLink="/roles">
              <a pRipple class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors text-lg">
                <i class="pi pi-users mr-2 text-lg"></i>
                <span class="font-medium">Rollen</span>
              </a>
            </li>
            <li *ngIf='showNavigation(scope.Account)' routerLink="/settings">
              <a pRipple class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors text-lg">
                <i class="pi pi-cog  mr-2 text-lg"></i>
                <span class="font-medium">Einstellungen</span>
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <div class="mt-auto">
      <hr class="mb-3 mx-3 border-top-1 border-none surface-border" />
      <a pRipple routerLink='settings' class="m-3 flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors">
        <p-avatar *ngIf="!me?.account?.logoUrl" shape="circle" [style]="{'background-color': '#817CCD', 'color': '#ffffff'}"
                  [label]="me?.firstName?.charAt(0) +  me?.lastName?.charAt(0)" size="xLarge"></p-avatar>
        <p-avatar *ngIf="me?.account?.logoUrl" [image]="env.restUrl + me?.account?.logoUrl" size="xLarge"></p-avatar>
        <span class="ml-2 text-700" *ngIf="!loading">{{me?.firstName}} {{me?.lastName}}</span>
      </a>
    </div>
  </div>
</div>
