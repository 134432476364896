import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {AuthLayoutComponent} from "./layouts/auth-layout/auth-layout.component";
import {DefaultLayoutComponent} from "./layouts/default-layout/default-layout.component";
import {AuthGuard} from "./auth/auth.guard";

const AUTH_ROUTES: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
];
const DASHBOARD_ROUTES: Routes = [
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'users',
    canActivate: [AuthGuard],
    loadChildren: () => import('./users/users.module').then(m => m.UsersModule)
  },
  {
    path: 'roles',
    canActivate: [AuthGuard],
    loadChildren: () => import('./roles/roles.module').then(m => m.RolesModule)
  },
  {
    path: 'branches',
    canActivate: [AuthGuard],
    loadChildren: () => import('./branches/branches.module').then(m => m.BranchesModule)
  },
  // {
  //   path: 'departments',
  //   canActivate: [AuthGuard],
  //   loadChildren: () => import('./departments/departments.module').then(m => m.DepartmentsModule)
  // },
  {
    path: 'settings',
    canActivate: [AuthGuard],
    loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule)
  },
];

const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full', },
  { path: '', component: AuthLayoutComponent, children: AUTH_ROUTES },
  { path: '', component: DefaultLayoutComponent, canActivate: [AuthGuard],  children: DASHBOARD_ROUTES }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
